import { useState, useEffect } from "react";
import { Frame, Loading, Header, VerContrato, Print } from "../components";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../config/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import moment from "moment";
import { Col, Row } from "react-bootstrap";

const Aceite = () => {
  const [dados, setDados] = useState({});
  const [loading, setLoading] = useState(true);

  let uid = auth.currentUser?.uid;

  const navigate = useNavigate();
  const setData = async () => {
    let status = "p";
    if (dados?.status === "a") status = "a";

    await setDoc(
      doc(db, "ebserh2025", uid),
      { status: status, aceite25: moment().format() },
      { merge: true }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    setData().then(() => navigate("../home"));
  };

  const getData = async () => {
    const qry = await getDoc(doc(db, "ebserh2025", uid));

    const data = qry.data();

    if (!data?.status || (data?.status === "a" && !data?.aceite25)) {
      setDados(data);
      setLoading(false);
    } else {
      navigate("../home");
    }
  };

  const checkLogin = async () => {
    if (uid) getData();
    else
      onAuthStateChanged(auth, (user) => {
        if (user?.uid) {
          uid = user.uid;
          getData();
        } else navigate("../login");
      });
  };

  useEffect(() => {
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Header nome={dados?.nome} foto={dados?.urlFoto} />
      {!loading && (
        <Frame
          body={
            <form autoComplete="off" onSubmit={handleSubmit} noValidate>
              <VerContrato dados={dados} />
              <Print />
              <Row className="mt-3">
                <Col className="d-grid">
                  <button type="submit" className="btn btn-primary">
                    LI E CONCORDO COM OS TERMOS DO SERVIÇO
                  </button>
                </Col>
              </Row>
            </form>
          }
        />
      )}
    </>
  );
};

export default Aceite;
